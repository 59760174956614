import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  StyledButtonSmall,
  StyledSelectInput,
  StyledText,
} from 'components/Wizard/Global/WizardStyled';
import { last } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import config from 'config';
import { clearAll, integrateFileText } from 'utils/apiFilesUploadHelpers';
import { setSolutionParam, setTourParameter } from 'actions';
import { useCookies } from 'react-cookie';
import { colors } from 'global/variables';
import withTranslation from '../../../hoc/withTranslation';
import {
  StyledContrastPanelHeader,
  StyledContrastPanelHeaderText,
} from '../../Wizard/Global/WizardStyledContrastPanel';
import {
  adjustDownloadGeoJSON,
  getPredefinedFiles,
  getLastValidIteration,
  hasOngoingAsyncRequest,
  isIterationSolution,
  removeProperties,
} from '../../../utils/SolutionHelpers';
import { APP_MODES, getAppMode } from '../../../utils/urlHelpers';
import { StyledCloseButton } from '../Menu/Menu';
import { isProdEnv } from '../../../utils/helpers';

const { white } = colors;

const StyledWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '1.3rem',
  alignItems: 'center',
}));

const StyledOption = styled.option(() => ({
  color: white,
  '&:hover': {
    backgroundColor: 'green',
  },
}));

const appMode = getAppMode();
const isProd = isProdEnv();
const showFullDev = appMode.includes(APP_MODES.DEVELOPER, APP_MODES.WIP);

const OPTIONS = {
  PROBLEM: 'problem',
  SOLUTION: 'solution',
  GEO: 'geoJson',
  INTERNAL_TOUR_PLANNER: 'tourPlanner',
  INTERNAL_USER: 'user',
  INTERNAL_ITERATIONS: 'iterations',
};
export const METHODS = {
  MANUAL: 'manual',
  DEMO: 'demo',
  IMPORT: 'import',
  JSON_IMPORT: 'json_import',
};

const DevConsoleHeader = ({
  translations: {
    error,
    devConsoleLabels: {
      configDesc,
      solutionUpload,
      configTitle,
      optionSolution,
      optionProblem,
      optionGeo,
      showPredefinedFiles,
      hidePredefinedFiles,
    },
  },
  setJsonObject,
  selectedOption,
  setSelectedOption,
  togglePanel,
  showPanel,
}) => {
  const dispatch = useDispatch();

  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const tourPlanner = useSelector(
    ({ tourPlanner: stateTourPlanner }) => stateTourPlanner.value[solution.show],
  );
  const oAuth = useSelector(({ oAuth: stateOAuth }) => stateOAuth);
  const [cookies] = useCookies(['apikey']);
  const PREDEFINED_FILES = config.predefinedFiles.sort();
  const user = useSelector(({ user: stateUser }) => stateUser);
  const [isSolution, setIsSolution] = useState();
  const [hasProblem, setHasProblem] = useState();
  const [fileNames, setFileNames] = useState({ filename: '', problemFileName: '' });
  const [predefinedFileName, setPredefinedFileName] = useState();
  const [predefinedFile, setPredefinedFile] = useState();
  const [showPredefined, setShowPredefined] = useState(false);

  const handleSetTourParameter = useCallback(
    (parameter) => dispatch(setTourParameter({ ...parameter, index: solution.show })),
    [dispatch, solution.show],
  );
  const handleSetSolutionParameter = useCallback(
    (data) => dispatch(setSolutionParam(data)),
    [dispatch],
  );

  const adjustDisplayedObject = useCallback(
    (option) => {
      setSelectedOption(option);
      const lastIteration = solution && last(solution.requests[solution.show].iterations);
      if (option === OPTIONS.INTERNAL_ITERATIONS) setJsonObject(removeProperties(solution));
      else if (option === OPTIONS.INTERNAL_TOUR_PLANNER) setJsonObject(tourPlanner);
      else if (option === OPTIONS.INTERNAL_USER) setJsonObject(user);
      else if (option === OPTIONS.GEO)
        setJsonObject(adjustDownloadGeoJSON(lastIteration.geoJSON.geo));
      else {
        const isSol = isIterationSolution(lastIteration);
        if (!lastIteration) setJsonObject({});
        else if (option === OPTIONS.PROBLEM) {
          setJsonObject(lastIteration.request.json);
        } else if (isSol && option === OPTIONS.SOLUTION)
          setJsonObject(
            removeProperties(
              lastIteration?.response ? lastIteration.response : getLastValidIteration(solution),
            ),
          );
      }
    },
    [solution, tourPlanner, user, setJsonObject, setSelectedOption],
  );

  useEffect(() => {
    const lastIteration = solution && last(solution.requests[solution.show].iterations);
    let isSol = false;
    if (lastIteration) {
      isSol = isIterationSolution(lastIteration);
      setIsSolution(isSol);
    }

    if (
      (selectedOption === OPTIONS.SOLUTION || selectedOption === OPTIONS.PROBLEM) &&
      !solution.json &&
      !hasOngoingAsyncRequest(solution.requests)
    ) {
      const changeTo = isSol ? OPTIONS.SOLUTION : OPTIONS.PROBLEM;
      adjustDisplayedObject(changeTo);
    } else if (selectedOption === OPTIONS.INTERNAL_ITERATIONS) {
      adjustDisplayedObject(OPTIONS.INTERNAL_ITERATIONS);
    }
  }, [solution]);

  useEffect(() => {
    const lastIteration = solution && last(solution.requests[solution.show].iterations);
    const lastReqName = solution && solution.requests[solution.show]?.name;
    const methodsArr = Object.keys(METHODS).map((k) => METHODS[k]);
    const filename = lastReqName && !methodsArr.includes(lastReqName) ? `(${lastReqName})` : '';
    const problemName = solution.requests[solution.show]?.problemFilename;
    const problemFileName = problemName ? `(${problemName})` : filename;
    setFileNames({ filename, problemFileName });
    setHasProblem(!isSolution || (isSolution && lastIteration.request.json.fleet.types));
  }, [setHasProblem, isSolution, solution]);

  useEffect(() => {
    if (!showPanel) return;
    adjustDisplayedObject(isSolution ? OPTIONS.SOLUTION : OPTIONS.PROBLEM);
  }, [tourPlanner, user, showPanel, isSolution, solution]);

  const handleOnChangeOption = useCallback(
    (e) => {
      const value = e.target.value;
      const defaultSwitch = Object.values(OPTIONS).includes(value);
      const predefinedSelected = value.endsWith('.json');

      if (defaultSwitch) adjustDisplayedObject(value);
      else if (!predefinedSelected && !defaultSwitch) setPredefinedFile();
      else {
        setPredefinedFileName(value);
      }
    },
    [tourPlanner, user, solution],
  );

  const handlePredefinedFile = useCallback(
    (file) => {
      handleSetTourParameter({ ordersMode: METHODS.JSON_IMPORT });
      clearAll(dispatch, !user.hasStateMemory, false, solution.show);
      handleSetSolutionParameter({ param: { name: predefinedFileName } });

      integrateFileText(
        JSON.stringify(file),
        'upload',
        'pasted',
        user,
        cookies,
        oAuth,
        dispatch,
        true,
        error,
        solution.show,
        false,
      );
      setPredefinedFileName();
      setPredefinedFile();
    },
    [
      error,
      cookies,
      dispatch,
      oAuth,
      user,
      handleSetSolutionParameter,
      handleSetTourParameter,
      solution.show,
      predefinedFileName,
    ],
  );

  useEffect(() => {
    if (predefinedFileName) {
      getPredefinedFiles(
        predefinedFileName,
        setPredefinedFile,
        setPredefinedFileName,
        dispatch,
        error,
      );
    }
  }, [predefinedFileName, dispatch, error]);

  useEffect(() => {
    if (predefinedFile) {
      handlePredefinedFile(predefinedFile);
    }
  }, [handlePredefinedFile, predefinedFile]);

  return (
    <>
      <StyledCloseButton onClick={() => togglePanel()} data-test-id="closeJsonViewer" />
      <StyledContrastPanelHeader size="0">
        <StyledWrapper>
          <StyledText bold contrast jsonTitle>
            {configTitle}
          </StyledText>
          {!isProd && (
            <StyledButtonSmall
              contrast={showPredefined}
              selected={!showPredefined}
              predefinedBtn
              onClick={() => setShowPredefined(!showPredefined)}
            >
              {showPredefined ? hidePredefinedFiles : showPredefinedFiles}
            </StyledButtonSmall>
          )}
        </StyledWrapper>
        <StyledContrastPanelHeaderText paddingBottom jsonTitle={isProd}>
          {configDesc}
          <br />
          {isProd && <i>{solutionUpload}</i>}
        </StyledContrastPanelHeaderText>
        <StyledSelectInput
          data-test-id="JSONViewerDropdown"
          contrast
          onChange={handleOnChangeOption}
          value={selectedOption}
        >
          <option value={OPTIONS.PROBLEM} key={OPTIONS.PROBLEM} disabled={!hasProblem}>
            {`${optionProblem} ${fileNames.problemFileName}`}
          </option>
          <option value={OPTIONS.SOLUTION} key={OPTIONS.SOLUTION} disabled={!isSolution}>
            {`${optionSolution} ${fileNames.filename}`}
          </option>
          <option value={OPTIONS.GEO} key={OPTIONS.GEO} disabled={!isSolution}>
            {`${optionGeo} ${fileNames.filename}`}
          </option>
          {showFullDev && (
            <>
              <option value={OPTIONS.INTERNAL_TOUR_PLANNER} key={OPTIONS.INTERNAL_TOUR_PLANNER}>
                Internal - Tour Planner
              </option>
              <option value={OPTIONS.INTERNAL_USER} key={OPTIONS.INTERNAL_USER}>
                Internal - User settings
              </option>
              <option value={OPTIONS.INTERNAL_ITERATIONS} key={OPTIONS.INTERNAL_ITERATIONS}>
                Internal - All planning iterations
              </option>
            </>
          )}
          {!isProd &&
            showPredefined &&
            PREDEFINED_FILES.map((file) => (
              <StyledOption value={file} key={file}>
                {file}
              </StyledOption>
            ))}
        </StyledSelectInput>
      </StyledContrastPanelHeader>
    </>
  );
};

export default withTranslation(DevConsoleHeader);
