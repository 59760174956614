import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getAsset } from 'utils/helpers';
import shakeHands from 'global/img/shake-hands.svg';
import { AmplitudeService } from '../../utils/amplitude';
import Button from '../Form/Button';
import { colors, fontSize } from '../../global/variables';
import { setUserCookies, setUserCookiesDisplay } from '../../actions';
import { StyledIcon } from '../Wizard/Global/WizardStyled';

const { normal } = fontSize;
const { black, white, darkBackground, primaryTextColorDarkBg, secondaryTextColorDarkBg } = colors;

const StyledNotification = styled.div({
  color: secondaryTextColorDarkBg,
  position: 'fixed',
  right: '1rem',
  bottom: '3rem',
  backgroundColor: darkBackground,
  zIndex: '999',
  width: '26rem',
  boxShadow: '0px 10px 29px 0px rgba(0, 0, 0, 0.22)',
  borderRadius: '0.25rem 0.25rem 2rem 2rem',
  backdropFilter: 'blur(10px)',
  textRendering: 'geometricPrecision',
  overflow: 'hidden',
  boxSizing: 'border-box',
  lineHeight: '1.25rem',
  h1: {
    marginBottom: '0px',
    paddingBottom: '4px',
    color: primaryTextColorDarkBg,
    opacity: 1,
  },
  strong: {
    color: `${white} !important`,
  },
  '@media screen and (max-width: 850px)': {
    left: 0,
    bottom: 0,
    width: '100%',
    height: 'auto',
    minHeight: '18rem',
    borderRadius: '0',
    backgroundColor: darkBackground,
    color: secondaryTextColorDarkBg,
    paddingBottom: '2rem',
    boxSizing: 'border-box',
    h1: {
      marginBottom: '0px',
      paddingBottom: '4px',
      color: primaryTextColorDarkBg,
      opacity: 1,
    },
    strong: {
      color: `${white} !important`,
    },
  },
});

const StyledHeaderContainer = styled.div({
  padding: '.6rem 1.5rem 0rem 1.5rem',
});

const StyledHeader = styled.h1({
  fontSize: normal,
  color: black,
  opacity: '0.8',
  fontFamily: 'FiraGO',
  display: 'inline-block',
  paddingLeft: '0.8rem',
  '@media screen and (max-width: 850px)': {
    maxWidth: '80%',
  },
});

const StyledBody = styled.div({
  padding: '0.3rem 1.5rem 0.7rem 1.5rem',
});

const StyledLinkContainer = styled.div({
  padding: '.2rem 1.5rem 1rem',
});

const StyledLink = styled.a({
  color: '#01b6b2',
  textDecorationLine: 'none',
  ':hover': {
    color: '#00a39f',
    textDecorationLine: 'underline',
  },
});

const StyledActions = styled.div({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  gridGap: '1rem',
  padding: '0.7rem 0.9rem 0.9rem 0.9rem',
  boxSizing: 'border-box',
});

const ShakeHandsIconStyle = css`
  background-image: url(${getAsset(shakeHands)});
  width: 1.4rem !important;
  height: 1.4rem !important;
  background-size: 1.2rem !important;
  background-position-y: 3px;
  margin: 0 !important;
`;

const CookieNotification = ({
  user,
  translations: {
    cookieNotice: { header, body, moreInfo, accept, decline },
  },
  linkLang,
}) => {
  const dispatch = useDispatch();
  const tourPlanner = useSelector(({ tourPlanner: stateTourPlanner }) => stateTourPlanner);
  const handleSetUserCoookies = useCallback((data) => dispatch(setUserCookies(data)), [dispatch]);
  const handleSetUserCoookiesDisplay = useCallback(
    (data) => dispatch(setUserCookiesDisplay(data)),
    [dispatch],
  );

  const setCookieParam = useCallback(
    (value, close) => {
      AmplitudeService.setOptIn(value);
      handleSetUserCoookies(value);
      if (value) {
        AmplitudeService.setFleetConfiguration(tourPlanner.value[tourPlanner.value.length - 1]);
        AmplitudeService.setUserParams(user);
      }
      if (close) handleSetUserCoookiesDisplay(false);
    },
    [handleSetUserCoookies, handleSetUserCoookiesDisplay, tourPlanner, user],
  );

  const handleDecline = useCallback(() => {
    setCookieParam(false, true);
  }, [setCookieParam]);

  const handleAccept = useCallback(() => {
    setCookieParam(true, true);
  }, [setCookieParam]);

  return (
    <StyledNotification>
      <StyledHeaderContainer>
        <StyledIcon icon={ShakeHandsIconStyle} />
        <StyledHeader>{header}</StyledHeader>
      </StyledHeaderContainer>
      <StyledBody>{body}</StyledBody>
      <StyledLinkContainer>
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href={`https://legal.here.com/${linkLang}/privacy/cookies`}
        >
          {moreInfo}
        </StyledLink>
      </StyledLinkContainer>

      <StyledActions>
        <Button inverted text={decline} onClick={handleDecline} />
        <Button dataTestId="acceptCookiesButton" text={accept} onClick={handleAccept} />
      </StyledActions>
    </StyledNotification>
  );
};

export default withTranslation(CookieNotification);
