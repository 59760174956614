import React, { useCallback, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { MapContainer, TileLayer, ZoomControl, ScaleControl } from 'react-leaflet';
import withTranslation from 'hoc/withTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { getAreaPolygonFormat } from 'utils/territories/TerritoryAreaConverter';
import { useCookies } from 'react-cookie';
import { isEqual } from 'lodash';
import getCountryISO2 from 'country-iso-3-to-2';
import { PANELS } from 'reducers/user';
import { getBounds, toBounds } from '../../utils/map/MapCalculations';
import config from '../../config';
import MapMarkers from './MapMarkers/MapMarkers';
import MapTourLines from './MapTourLines';
import { fitMapBounds, getMapSettings } from '../../utils/map/MapUtils';
import { getGeoInfoFromObjects, getOriginalLines } from '../../utils/map/MapTourLineUtils';
import {
  accessLogging,
  TIMEOUT_VALUES,
  getRoutingTourInfo,
  selectUnassigned,
  setTerritoryParameter,
  selectTourById,
} from '../../actions';
import { tourToRoutingRequest } from '../../utils/RoutingConverter';
import MapAreas from './MapAreas';
import { APP_MODES, getAppMode } from '../../utils/urlHelpers';
import { getTerritoriesGeoJSON } from '../../utils/territories/TerritoriesHelpers';
import LocationSelector from './LocationSelector';
import { isProdEnv } from '../../utils/helpers';
import { getGroupsPerTour, getMapUrl } from '../../utils/MapHelpers';
import {
  generateAllTourConcaveHull,
  generateAllTourConvexHull,
} from '../../utils/territories/TerritoriesFromJson';

const {
  map: { url, pviewUrl },
} = config;
const appMode = getAppMode();
const isProd = isProdEnv();
const terraFF = appMode.includes(APP_MODES.TERRA);

const MAX_MAP_ZOOM = 18;
const MIN_MAP_ZOOM = 2;

const StyledMap = styled.div({
  width: '100%',
  overflow: 'hidden',
  position: 'fixed',
});

const Map = ({
  mapData,
  oAuth,
  user,
  display,
  routingData,
  isSolution,
  tourData,
  translations,
  tourPlanner,
  solutionId,
}) => {
  const dispatch = useDispatch();
  const mapLayer = useRef(null);
  const currentStep = useSelector((state) => state.usageContext.currentStep);
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const orders = useSelector((state) => state.orders[solution.show]);
  const { showTourConvexHull, showTourConcaveHull, satelliteView } = user.mapSettings;
  const { territories, groupAreas, avoidAreas } = useSelector(
    (state) => state.areas[solution.show],
  );
  const hideRoutes = user.mapSettings.hideAllRoutes;

  const handleSetTerritoryParameter = useCallback(
    (parameter) => dispatch(setTerritoryParameter(parameter)),
    [dispatch],
  );
  const handleSelectTourById = useCallback((data) => dispatch(selectTourById(data)), [dispatch]);
  const handleSelectUnassigned = useCallback(() => dispatch(selectUnassigned(true)), [dispatch]);
  const handleGetRoutingTourInfo = useCallback(
    (index, tour) =>
      dispatch(
        getRoutingTourInfo({
          oAuth,
          routingRequest: tourToRoutingRequest(tour, tourPlanner, orders),
          tourId: index,
          solutionId,
        }),
      ),
    [dispatch, oAuth, tourPlanner, solutionId, orders],
  );
  const userDistance = user.distance;
  const [areaPolygon, setAreaPolygon] = useState(null);
  const [selectedAreasList, setSelectedAreasList] = useState(null);
  const [routingRoutes, setRoutingRoutes] = useState(null);
  const [originalLocation, setOriginalLocation] = useState(null);
  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState();
  const [cookies] = useCookies(['apikey']);
  const [basePoliticalViews, setBasePoliticalViews] = useState([]);
  const ISO2Code = getCountryISO2(user.politicalView);
  const hasPView = basePoliticalViews.includes(ISO2Code);
  const hasApiKey = cookies.apikey && cookies.apikey !== '';
  const markerRestrictions =
    (tourPlanner.ordersMode === 'manual' && currentStep === 2) || solution.json;
  const allowMarkers = isProd ? markerRestrictions && hasApiKey : markerRestrictions;
  const [tourHulls, setTourHulls] = useState([]);
  const validStep = [3, 4].includes(currentStep);

  useEffect(() => {
    if (showTourConvexHull && validStep) {
      setTourHulls(generateAllTourConvexHull(tourData));
    } else if (showTourConcaveHull && showTourConcaveHull.enabled && validStep) {
      setTourHulls(generateAllTourConcaveHull(tourData, showTourConcaveHull.value));
    } else {
      setTourHulls([]);
    }
  }, [tourData, showTourConvexHull, showTourConcaveHull, validStep]);

  const indexes = display.selectedTour !== undefined ? [display.selectedTour] : display.routeIds;
  const groupsToShow = getGroupsPerTour(tourData, indexes, solution);
  const showTourHulls = tourHulls.length > 0 && !display.showUnassigned;
  const showMapAreas = areaPolygon != null && (territories.isEnabled || showTourHulls);

  const showUnassigned = useCallback(() => {
    handleSelectUnassigned();
  }, [handleSelectUnassigned]);

  const getPoliticalViews = async () => {
    axios
      .get(pviewUrl)
      .then((response) => {
        setBasePoliticalViews(response.data.base);
      })
      .catch((error) => {
        console.error('Error: ', error);
      });
  };

  const activateTour = useCallback(
    (index, tour) => {
      if (display.selectedTour !== index) {
        handleSelectTourById({ selectedTour: index, noMapMovement: true });
        handleSelectTourById({ selectOne: index });
        handleGetRoutingTourInfo(index, tour);
      }
    },
    [handleSelectTourById, handleGetRoutingTourInfo, display.selectedTour],
  );

  useEffect(() => {
    if (terraFF) setAreaPolygon(getAreaPolygonFormat(territories.areaDetails));
    else
      setAreaPolygon(
        getTerritoriesGeoJSON(
          territories.areaDetails,
          avoidAreas.areaDetails,
          groupAreas.areaDetails,
          showTourHulls ? tourHulls : [],
        ),
      );
  }, [territories, groupAreas, avoidAreas, tourHulls, showTourHulls]);

  useEffect(() => {
    const newBounds = getBounds(mapData, user, display, routingRoutes);
    const shouldMove =
      !user.openPanel || !user.openPanel === PANELS.MAP_SETTINGS || display.noMapMovement === false;
    if (
      map &&
      !tourPlanner.editedOrder?.customJobAddress &&
      !isEqual(newBounds, bounds) &&
      shouldMove &&
      !display.noMapMovement
    ) {
      setBounds(newBounds);
      setTimeout(() => {
        fitMapBounds(map, newBounds);
      }, TIMEOUT_VALUES.HIGH);
    }
  }, [
    mapData,
    user,
    display.showProblem,
    display.showUnassigned,
    display.selectedTour,
    display.noMapMovement,
    routingRoutes,
    bounds,
    tourPlanner.editedOrder,
    display,
    map,
  ]);

  useEffect(() => {
    const routeRequestError = routingData && routingData.requestError;
    const routes =
      routeRequestError || user.mapSettings.showStraightRouteLines
        ? null
        : getGeoInfoFromObjects(routingData);
    setRoutingRoutes(routes);
    const lines = routeRequestError ? null : getOriginalLines(routingData);
    setOriginalLocation(lines);
  }, [routingData, setRoutingRoutes, user.mapSettings.showStraightRouteLines]);

  const handleOnSelect = useCallback(
    (value) => {
      const selectedAreaValue = value.postal_code;
      const newSelectedAreas = { value: selectedAreaValue };
      setSelectedAreasList(newSelectedAreas);
      handleSetTerritoryParameter({ selectedAreas: newSelectedAreas });
    },
    [selectedAreasList, territories, handleSetTerritoryParameter],
  );

  useEffect(() => {
    getPoliticalViews();
  }, []);

  useEffect(() => {
    if (mapLayer.current) {
      mapLayer.current.setUrl(
        getMapUrl(url, translations.map.language, ISO2Code, hasPView, satelliteView),
      );
    }
  }, [hasPView, ISO2Code, translations.map.language, satelliteView]);

  return (
    <StyledMap user={user} translations={translations}>
      <MapContainer
        attributionControl={false}
        zoomControl={false}
        bounds={toBounds(getBounds(mapData, user, display, routingRoutes))}
        boundsOptions={getMapSettings()}
        useFly
        maxZoom={MAX_MAP_ZOOM}
        minZoom={MIN_MAP_ZOOM}
        whenCreated={setMap}
      >
        {allowMarkers && oAuth && <LocationSelector oAuth={oAuth} display={display} />}
        {showMapAreas ? (
          <MapAreas
            areaPolygon={areaPolygon}
            onClick={handleOnSelect}
            groupsToShow={groupsToShow}
          />
        ) : null}
        <TileLayer
          attribution="Here Maps"
          url={getMapUrl(url, translations.map.language, ISO2Code, hasPView, satelliteView)}
          ref={mapLayer}
          eventHandlers={{
            tileerror: () => {
              accessLogging(false, url, 'apiKey', null);
            },
            error: () => {
              accessLogging(false, url, 'apiKey', null);
            },
            locationerror: () => {
              accessLogging(false, url, 'apiKey', null);
            },
          }}
        />
        <ZoomControl position="topright" zoomInTitle="" zoomOutTitle="" />
        <ScaleControl
          position="bottomright"
          metric={userDistance === 'metric'}
          imperial={userDistance === 'imperial'}
        />
        {mapData && oAuth && (
          <MapMarkers
            mapData={mapData}
            map={map}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            routingData={routingData}
            tourData={tourData}
            isSolution={isSolution}
            activateTour={activateTour}
            showUnassigned={showUnassigned}
            oAuth={oAuth}
            tourPlanner={tourPlanner}
            solution={solution}
            orders={orders}
          />
        )}
        {isSolution && !hideRoutes && (
          <MapTourLines
            mapData={mapData}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            routingData={routingData}
            tourData={tourData}
            activateTour={activateTour}
            originalLocation={originalLocation}
          />
        )}
      </MapContainer>
    </StyledMap>
  );
};

export default withTranslation(Map);
