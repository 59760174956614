import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import arrowRight from '../../../global/img/arrow-right.svg';
import arrowLeft from '../../../global/img/arrow-left.svg';
import { getAsset } from '../../../utils/helpers';
import Section from '../../Form/Section';

const Icon = styled.button(({ icon, disabledStyle }) => ({
  backgroundImage: `url(${getAsset(icon)})`,
  opacity: disabledStyle ? '0.2' : '1',
  height: '1rem',
  width: '1rem',
  backgroundRepeat: 'no-repeat',
  border: 'none',
  cursor: disabledStyle ? 'mouse' : 'pointer',
  backgroundColor: 'transparent',
}));

const StyledPagination = styled.div({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
});

const PaginationNumber = styled.span({
  fontWeight: 'bold',
});

const Pagination = ({ numberOfPages, currentItem, setCurrentItem }) => {
  const disabledBefore = currentItem === 0;
  const disabledAfter = currentItem === numberOfPages - 1;

  const onArrowLeftClick = useCallback(() => {
    setCurrentItem(currentItem - 1);
  }, [currentItem, setCurrentItem]);

  const onArrowRightClick = useCallback(() => {
    setCurrentItem(currentItem + 1);
  }, [currentItem, setCurrentItem]);

  return (
    <Section>
      <StyledPagination>
        <Icon
          data-test-id="pageLeftArrow"
          onClick={onArrowLeftClick}
          icon={arrowLeft}
          disabledStyle={disabledBefore}
          disabled={disabledBefore}
        />
        <PaginationNumber>{currentItem + 1}</PaginationNumber>
        <Icon
          data-test-id="pageRightArrow"
          onClick={onArrowRightClick}
          icon={arrowRight}
          disabledStyle={disabledAfter}
          disabled={disabledAfter}
        />
      </StyledPagination>
    </Section>
  );
};

export default Pagination;
