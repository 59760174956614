import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withTranslation from 'hoc/withTranslation';
import {
  RETURN_LOCATION_MODE,
  selectTourById,
  setSolutionJson,
  setTourParameter,
  updateOrder,
} from 'actions';
import { v4 } from 'uuid';
import { hasOngoingAsyncRequest } from 'utils/SolutionHelpers';
import {
  isActivityToDisplay,
  isPointDepot,
  isPointReturnLocation,
  isUnassignedJob,
} from '../../../utils/map/MapFeatureFilters';
import { makeMarker } from '../../../utils/map/MapMarkersUtils';
import { createClusterCustomIcon } from '../../../utils/map/MapClusterUtils';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { getLocationAddress } from '../../../utils/GeoCoder';
import { getSafeValue } from '../../../utils/security';
import ActivityMarkers from './ActivityMarkers';
import StartEndLocations from './StartEndLocations';
import StopMarkers from './StopMarkers';
import JobMarkers from './JobMarkers';
import UnselectedMarkers from './UnselectedMarkers';

export const DEFAULT_CLUSTER_RADIUS = 50;
export const MIN_CLUSTER_RADIUS = 40;
export const CLUSTER_BREAKPOINT_MAP_ZOOM = 12;

export const ACTIVITY_DEFAULT_CLUSTER_RADIUS = 100;
export const ACTIVITY_MIN_CLUSTER_RADIUS = 20;

const MapMarkers = ({
  mapData,
  map,
  user,
  display,
  routingRoutes,
  tourData,
  routingData,
  isSolution,
  activateTour,
  showUnassigned,
  translations,
  oAuth,
  tourPlanner,
  solution,
  orders,
  helpers: { formatDuration },
}) => {
  const dispatch = useDispatch();
  const removeHighlight =
    useSelector(({ usageContext: stateUsageContext }) => stateUsageContext).currentStep === 4;
  const hourOffset = tourPlanner.offset;
  const displayUnselected = isSolution && tourData;
  const rechargeDistances = mapData.geo.features
    .map((feature) => feature.properties.distance)
    .filter((p) => p > 0);
  const [highlightStop, setHighlightStop] = useState({ id: -1, tourId: 0 });
  const [highlightUnassigned, setHighlightUnassigned] = useState(false);
  const handleSetTourParameter = useCallback(
    (parameter) => dispatch(setTourParameter({ ...parameter, index: solution.show })),
    [dispatch, solution.show],
  );
  const handleSelectTourById = useCallback((data) => dispatch(selectTourById(data)), [dispatch]);
  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );

  const handleUpdateOrder = useCallback(
    (o, idx, ordIdx) => dispatch(updateOrder(o, idx, ordIdx)),
    [dispatch],
  );

  const onMarkerClick = useCallback(
    (feature) => {
      const isUnassigned = isUnassignedJob(feature);
      const tourId = feature.properties.routeId;
      if (isUnassigned) {
        showUnassigned();
        setHighlightUnassigned((prev) => isUnassigned && !prev);
      }
      if (tourId === undefined) return;

      if (display.selectedTour !== tourId) {
        const tour = getSafeValue(tourData.tours, tourId);
        activateTour(tourId, tour);
        AmplitudeService.log(AMPLITUDE_EVENTS.MAP_MARKER_ACTIVATE_TOUR);
        setHighlightStop({ id: -1, tourId });
      } else {
        const id = isActivityToDisplay(feature)
          ? feature.properties.stopIndex
          : feature.properties.jobOrder;

        setHighlightStop((prev) =>
          prev.id === id || prev.tourId !== tourId ? { id: -1, tourId } : { id, tourId },
        );
      }
    },
    [tourData, activateTour, showUnassigned],
  );

  const onMarkerDragEnd = useCallback(
    (m, feature) => {
      getLocationAddress(m.getLatLng(), oAuth, user).then((item) => {
        const loc = {
          label: item.address.label,
          value: item.position,
        };
        const isEditing = feature.properties.isEditing;
        const featureID = feature.properties.id;
        handleSelectTourById({ selectedTour: display.selectedTour, noMapMovement: true });

        if (isPointDepot(feature)) {
          const toSet = { location: loc };
          if (tourPlanner.returnLocationMode === RETURN_LOCATION_MODE.START)
            toSet.returnLocation = loc;
          handleSetTourParameter(toSet);
        } else if (isPointReturnLocation(feature)) handleSetTourParameter({ returnLocation: loc });
        else {
          const orderIndex = orders.findIndex((order) => order.InternalID === featureID);
          const newOrder = {
            ...(isEditing ? tourPlanner.editedOrder : orders[orderIndex]),
            InternalID: !isEditing ? orders[orderIndex].InternalID : v4(),
            Address: loc.label,
            Latitude: loc.value.lat,
            Longitude: loc.value.lng,
            Activity: feature.properties.types[0],
            Dragged: true,
            TimeCreated: Date.now(),
          };

          if (!isEditing) {
            handleUpdateOrder(newOrder, solution.show, orderIndex === -1 ? null : orderIndex);
            if (solution.json) handleSetSolutionJson({ jsonUserChange: 'orders' });
          } else
            handleSetTourParameter({
              editedOrder: {
                ...tourPlanner.editedOrder,
                ...newOrder,
              },
            });
        }
      });
    },
    [
      oAuth,
      user,
      tourPlanner,
      handleSetTourParameter,
      handleUpdateOrder,
      orders,
      solution.show,
      solution.json,
      handleSetSolutionJson,
      handleSelectTourById,
      display.selectedTour,
    ],
  );

  const makeMapMarkers = useCallback(
    (feature, latlng) => {
      const highlight =
        display.selectedTour === highlightStop.tourId &&
        (feature.properties.jobOrder === highlightStop.id ||
          feature.properties.stopIndex === highlightStop.id);
      return makeMarker(
        feature,
        latlng,
        display,
        onMarkerClick,
        translations,
        onMarkerDragEnd,
        isSolution,
        hourOffset,
        highlight,
        highlightUnassigned,
        hasOngoingAsyncRequest(solution.requests),
        dispatch,
        orders,
        solution,
        formatDuration,
        rechargeDistances,
      );
    },
    [
      display,
      translations,
      isSolution,
      hourOffset,
      highlightStop,
      onMarkerClick,
      onMarkerDragEnd,
      highlightUnassigned,
      solution.requests,
      dispatch,
      orders,
      solution,
      rechargeDistances,
    ],
  );

  const createClusterIcon = useCallback(
    (cluster) => {
      return createClusterCustomIcon(cluster, highlightStop, map, translations);
    },
    [highlightStop, translations, map, translations],
  );

  useEffect(() => {
    if (removeHighlight) setHighlightUnassigned(false);
  }, [removeHighlight]);

  return (
    <>
      <UnselectedMarkers
        makeMapMarkers={makeMapMarkers}
        mapData={mapData}
        user={user}
        display={display}
        routingRoutes={routingRoutes}
        displayUnselected={displayUnselected}
        highlightUnassigned={highlightUnassigned}
      />

      <StopMarkers
        makeMapMarkers={makeMapMarkers}
        mapData={mapData}
        user={user}
        display={display}
        routingRoutes={routingRoutes}
        highlightStop={highlightStop}
        createClusterIcon={createClusterIcon}
        isSolution={isSolution}
        routingData={routingData}
      />
      {display.showProblem && (
        <JobMarkers
          makeMapMarkers={makeMapMarkers}
          mapData={mapData}
          user={user}
          display={display}
          routingRoutes={routingRoutes}
          highlightStop={highlightStop}
          createClusterIcon={createClusterIcon}
          isSolution={isSolution}
          routingData={routingData}
        />
      )}

      {user.mapSettings.showStops && (
        <>
          <StartEndLocations
            makeMapMarkers={makeMapMarkers}
            mapData={mapData}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            routingData={routingData}
            isSolution={isSolution}
          />
          <ActivityMarkers
            mapData={mapData}
            user={user}
            display={display}
            routingRoutes={routingRoutes}
            highlightStop={highlightStop}
            makeMapMarkers={makeMapMarkers}
            createClusterIcon={createClusterIcon}
          />
        </>
      )}
    </>
  );
};

export default withTranslation(MapMarkers);
