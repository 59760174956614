import { cloneDeep, isEmpty } from 'lodash';
import {
  CLEAR_ORDERS,
  CLEAR_SOLUTION,
  DELETE_SOLUTION,
  GET_SOLUTION,
  SET_ORDERS,
  SET_ORDERS_DEMO,
  UPDATE_ORDER,
  USER_SET_PARAM,
} from '../actions';
import {
  getStateFromMemory,
  setStateToMemory,
  shouldRememberLastState,
} from '../utils/MemoryHelpers';
import { setStorageValue, STORAGE_IDS } from '../utils/localStorageHelpers';
import { setSafeValue } from '../utils/security';

const stateID = STORAGE_IDS.orders;
const initialState = getStateFromMemory(stateID, [[]]);

export default (state = initialState, { payload = [], type, index, orderIdx }) => {
  switch (type) {
    case SET_ORDERS: {
      const newState = cloneDeep(state);
      newState[index ?? newState.length - 1] = payload;
      setStateToMemory(stateID, newState);
      return newState;
    }
    case SET_ORDERS_DEMO: {
      const newState = cloneDeep(state);
      newState[index ?? newState.length - 1] =
        newState[index ?? newState.length - 1].concat(payload);
      return newState;
    }
    case CLEAR_ORDERS: {
      const newState = cloneDeep(state);
      const idx = payload.index !== undefined ? payload.index : newState.length - 1;
      newState[idx] = [];
      setStateToMemory(stateID, newState);
      return newState;
    }
    case UPDATE_ORDER: {
      const newState = cloneDeep(state);
      const lastOrdersIndex = index ?? newState.length - 1;
      const orderIndex =
        orderIdx ?? newState[lastOrdersIndex].findIndex((o) => o.InternalID === payload.InternalID);

      setSafeValue(newState[lastOrdersIndex], orderIndex, payload);
      return newState;
    }
    case GET_SOLUTION: {
      const newState = cloneDeep(state);
      const unassignedSol =
        (payload.data?.notices || payload.data?.unassigned) && payload.data.tours.length === 0;

      if (shouldRememberLastState()) {
        if ((payload.error && newState.length >= 1 && !payload.isDemoOrders) || unassignedSol) {
          newState[newState.length - 1] = [];
          return newState;
        }
        if (newState[0].length === 0) newState.shift();
        if (newState.length > 3) newState.shift();
        if (newState[payload.index ?? newState.length - 1].length !== 0) {
          const idx = payload.index ?? newState.length - 1;
          if (payload.keepOrders) newState[idx + 1] = newState[idx];
          else newState[newState.length] = [];
        }
        setStateToMemory(stateID, newState);
        return newState;
      }

      if (!payload.keepOrders && newState[payload.index ?? newState.length - 1].length !== 0)
        newState[payload.index ?? newState.length] = [];
      return newState;
    }
    case CLEAR_SOLUTION: {
      const newState = cloneDeep(state);

      if (newState.length > 3 || (!shouldRememberLastState() && newState.length > 1)) {
        newState.shift();
      }
      setStateToMemory(stateID, newState);
      return newState;
    }
    case DELETE_SOLUTION: {
      let newState = cloneDeep(state);

      if (newState.length === 1) {
        newState.splice(payload.index, 1);
      }
      newState = newState.filter((n, idx) => !payload.index.includes(idx));

      if (payload.toEmptyLast) newState[newState.length - 1] = [];

      if (newState.length === 0) newState.push([]);

      setStorageValue(stateID, newState);
      return newState;
    }
    case USER_SET_PARAM: {
      if (payload.hasStateMemory === false) {
        const newState = [state[state.length - 1]];
        setStorageValue(stateID, newState);
        return newState;
      }
      if (payload.hasStateMemory === true) {
        const newState = cloneDeep(state);
        if (!isEmpty(newState[0])) newState.push([]);
        return newState;
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
